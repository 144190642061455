<template>
  <div>
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>Berita</span>
        <p class="text-center">
          Berita Badan Penyelenggara Jaminan Produk Halal
        </p>
      </div>
    </div>
    <div class="container">
      <div class="_sub_news">
        <div class="row">
          <div class="col-md-7">
            <div class="content mb-40">
              <div class="container-input-search">
                <div class="input-group-for-search">
                  <form @submit.prevent="cariBerita">
                    <input placeholder="Cari berita" type="text" v-model="search" />
                    <button type="submit"> 
                      <i class="fa fa-search fa-flip-horizontal  "></i>
                    </button>
                  </form>
                </div>
                <div class="for-btn-filter">
                  <button type="button" @click="changeSort">
                    <i :class="{ 'fa fa-sort-desc': !sortType, 'fa fa-sort-asc': sortType }"></i>
                  </button>
                </div>
              </div>
              <template v-for="(b, i) in berita">
                <div class="_content-news mb-10" :key="i">
                  <template v-if="b.banner">
                    <img :src="b.banner" alt="Berita">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/compro/misc/default-image.jpg" alt="">
                  </template>
                  <div class="_sub">
                    <h4 class=" elipsis-2-char font-14  font-weight-6 mb-5 ln-2">{{ b.title }}</h4>
                    <span class="d-block font-12 color-grey mb-10">{{ b.timestamp_publish_at | date_only }} | {{ b.timestamp_publish_at | time_only }}</span>
                    <p class="mb-19 elipsis-2-char ln-2  font-14 color-grey-dark">
                      {{ b.content | sort_desc }}
                    </p>
                    <a :href="`/berita/${b.slug}`" class="trasition-bounce font-14 color-primary font-weight-6 d-inline-block ">Baca selengkapnya</a>
                  </div>
                </div>
              </template>
              <div class="mt-3 no-last style_me"> 
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="news_sugest mb-20">
              <h3 class="title font-weight-6 font-18 mb-19">Informasi Terbaru</h3>
              <template v-for="(berita, index) in beritaTerbaru">
                <div class="for-loop _news" :key="index">
                  <router-link :to="`/berita/${berita.slug}`">
                    <template v-if="berita.banner">
                      <img :src="berita.banner" alt="Berita">
                    </template>
                    <template v-else>
                      <img src="@/assets/images/compro/misc/default-image.jpg" alt="">
                    </template>
                    <h2 class="font-weight-6 font-14 elipsis-2-char">{{ berita.title | sort_desc(80) }}</h2>
                    <p class="font-14 color-grey-dark elipsis-2-char" style="padding-left: 120px">
                      {{ berita.content | sort_desc(80) }}
                    </p>
                    <span class="for-date">{{ berita.timestamp_publish_at | date_only }} | {{ berita.timestamp_publish_at | time_only }}</span>
                  </router-link>
                </div>
              </template>
            </div>
            <div class="news_sugest mb-20">
              <h3 class="title font-weight-6 font-18 mb-19">Berita Terpopuler</h3>
              <template v-for="(berita, index) in beritaPopuler">
                <div class="for-loop _news" :key="index">
                  <router-link :to="`/berita/${berita.slug}`">
                    <template v-if="berita.banner">
                      <img :src="berita.banner" alt="Berita">
                    </template>
                    <template v-else>
                      <img src="@/assets/images/compro/misc/default-image.jpg" alt="">
                    </template>
                    <h2 class="font-weight-6 font-14  elipsis-2-char">{{ berita.title | sort_desc(80) }}</h2>
                    <p class="font-14 color-grey-dark elipsis-2-char" style="padding-left: 120px">
                      {{ berita.content | sort_desc(80) }}
                    </p>
                    <span class="for-date">{{ berita.timestamp_publish_at | date_only }} | {{ berita.timestamp_publish_at | time_only }}</span>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list-berita',
  data() {
    return {
      berita: [],
      beritaPopuler: [],
      beritaTerbaru: [],
      search: null,
      rows: 0,
      perPage: 3,
      currentPage: 1,
      sortType: false
    };
  },
  watch: {
    currentPage() {
      this.getBerita()
    }
  },
  mounted() {
    this.getBerita()
    this.getBeritaTerbaru()
    this.getBeritaPopuler()
  },
  methods: {
    cariBerita() {
      this.currentPage = 1
      this.getBerita()
    },
    changeSort () {
      this.currentPage = 1
      this.sortType = !this.sortType
      this.getBerita()
    },
    getBerita() {
      this.axios.get(`news`, {
        params: {
          page: this.currentPage,
          limit: this.perPage,
          search: this.search,
          status: 1,
          sortBy: 'publish_at',
          sortType: (this.sortType)? 'ASC' : 'DESC',
        }
      }).then( response => {
        this.berita = response.data.result.news
        this.rows = response.data.result.total
      })
    },
    getBeritaPopuler() {
      this.axios.get(`news`, {
        params: {
          page: 1,
          limit: 5,
          sortBy: 'viewers',
          sortType: 'DESC',
          status: 1
        }
      }).then( response => {
        this.beritaPopuler = response.data.result.news
      })
    },
    getBeritaTerbaru() {
      this.axios.get(`news`, {
        params: {
          page: 1,
          limit: 5,
          sortBy: 'publish_at',
          sortType: 'DESC',
          status: 1
        }
      }).then( response => {
        this.beritaTerbaru = response.data.result.news
      })
    }
  }
};
</script>